import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import AppLogo from '../../../components/AppLogo'
import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import {
  DEFAULT_DATE_REGEX,
  EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX,
} from '../../../constants/regex'
import { useSaveContactInfo } from '../../../mutations/eligibility/SaveContactInvalidVerification'
import type { UserAttemptInfo } from '../../../types/Register'
import { formatPhoneNumber, isValidDefaultDate } from '../../../helpers/generic'
import { useRegister } from '../../../contexts/RegisterContext'
import Tooltip from '../../../components/Tooltip'
import DatePicker from '../../../components/DatePicker'
import { useAuth } from '../../../contexts/AuthProvider'

const formSchema = (is4thFieldEmail: boolean, isEmployer: boolean) => {
  let schema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required.')
      .matches(EMAIL_REGEX, 'Please enter a valid email address.')
      .trim(),
    phoneNumber: Yup.string().matches(VALID_PHONE_NUMBER_REGEX, {
      name: 'validPhoneNumber',
      message: 'Please complete the phone number validly.',
      excludeEmptyString: true,
    }),
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    birthDate: Yup.string()
      .required('Birthdate is required.')
      .matches(
        DEFAULT_DATE_REGEX,
        'Birthdate must be in valid MM/DD/YYYY format.'
      )
      .test('valid-date', 'Birthdate is not valid.', (enteredDate: string) =>
        isValidDefaultDate(enteredDate)
      )
      .test(
        'test-birth-date-future',
        'Birthdate cannot be in the future.',
        (enteredDate: string) => {
          const date = new Date(enteredDate)
          const now = new Date()
          return date <= now
        }
      )
      .trim(),
  })

  if (is4thFieldEmail)
    schema = schema.concat(
      Yup.object().shape({
        externalId: Yup.string().required('Email is required.').trim(),
      })
    )
  else
    schema = schema.concat(
      Yup.object().shape({
        externalId: Yup.string()
          .required(
            isEmployer ? 'Employee ID is required.' : 'Student ID is required.'
          )
          .trim(),
      })
    )

  return schema
}

const InvalidVerification: React.FC = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { forSelf: userForSelf } = useRegister()
  const { mutate: callSaveContactInfo, isLoading } = useSaveContactInfo()
  const clientInfo = user.data.clientData
  const is4thFieldEmail: boolean = clientInfo?.importer4thId?.includes('EMAIL')
  const isEmployer: boolean = clientInfo?.clientType === 'EMPLOYER'
  const forSelf: boolean = isEmployer || userForSelf
  const userInfo = location?.state?.userInfo

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    control,
  } = useForm<UserAttemptInfo>({
    defaultValues: {
      birthDate: userInfo?.birthDate,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      externalId: userInfo?.externalId || userInfo?.email,
    },
    mode: 'all',
    resolver: yupResolver(formSchema(is4thFieldEmail, isEmployer)),
  })

  const submit = (info: UserAttemptInfo) =>
    callSaveContactInfo(
      {
        email: info.email,
        clientName: clientInfo.clientName,
        phoneNumber: info.phoneNumber,
        birthDate: userInfo?.birthDate,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        externalId: userInfo?.externalId || userInfo?.email,
      },
      {
        onSuccess: () => navigate('/sign-up/verify/submitted'),
      }
    )

  return (
    <div className="flex flex-col gap-8 text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-lg font-semibold">
          Let's figure this out
        </p>
        <p className="text-center text-sm">
          Looks like you're having trouble verifying your eligibility. Don't
          worry, have our support team contact you to get this figured out!
        </p>
      </div>

      {/* Form */}
      <form
        onSubmit={handleSubmit((data: UserAttemptInfo) => submit(data))}
        className="w-full xs:px-4"
      >
        <div className="grid grid-cols-2 gap-4">
          {/* Email */}
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-base font-semibold xs:text-sm"
            >
              Your email
            </label>
            <div className="relative mt-1">
              <input
                type="text"
                className={errors.email ? inputErrorClass : inputValidClass}
                placeholder="Enter"
                {...register('email')}
              />
              {errors.email && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            <p className="mt-2 text-sm text-status-error">
              {errors.email?.message}
            </p>
          </div>

          {/* Phone Number */}
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue=""
            rules={{ maxLength: 14 }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-2 flex flex-col gap-1">
                <label
                  htmlFor="phoneNumber"
                  className="text-sm font-semibold sm:text-base"
                >
                  Phone number (optional)
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={`${
                      Boolean(error && error.type !== 'validPhoneNumber')
                        ? inputErrorClass
                        : inputValidClass
                    } font-normal`}
                    placeholder="(000) 000-0000"
                    {...field}
                    onChange={(e) => {
                      field.onChange(formatPhoneNumber(e.target.value))
                    }}
                    maxLength={14}
                  />
                  {Boolean(error && error.type !== 'validPhoneNumber') && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-status-error"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {Boolean(error) && (
                  <p
                    className={`text-sm ${
                      Boolean(error && error.type !== 'validPhoneNumber')
                        ? 'text-status-error'
                        : ''
                    }`}
                  >
                    {error.message}
                  </p>
                )}
              </div>
            )}
          />

          {/* First name */}
          <div className="xs:col-span-2">
            <label
              htmlFor="firstName"
              className="block text-base font-semibold text-text-primary xs:text-sm"
            >
              {forSelf ? 'First name' : "Child's first name"}
            </label>
            <div className="relative mt-1">
              <input
                type="text"
                className={inputValidClass}
                placeholder="First"
                {...register('firstName')}
              />
              {errors.firstName && (
                <p className="mt-1 text-sm text-status-error">
                  {errors.firstName.message}
                </p>
              )}
            </div>
          </div>

          {/* Last name */}
          <div className="xs:col-span-2">
            <label
              htmlFor="lastName"
              className="block text-base font-semibold text-text-primary xs:text-sm"
            >
              {forSelf ? 'Last name' : "Child's last name"}
            </label>
            <div className="relative mt-1">
              <input
                type="text"
                className={inputValidClass}
                placeholder="Last"
                {...register('lastName')}
              />
              {errors.lastName && (
                <p className="mt-1 text-sm text-status-error">
                  {errors.lastName.message}
                </p>
              )}
            </div>
          </div>

          {/* Date of birth */}
          <div className="xs:col-span-2">
            <label
              htmlFor="birthDate"
              className="block text-base font-semibold text-text-primary xs:text-sm"
            >
              {forSelf ? 'Date of birth' : "Child's date of birth"}
            </label>
            <div className="relative mt-1">
              <DatePicker
                setValue={setValue}
                control={control}
                fieldKey="birthDate"
              />
            </div>
            {errors.birthDate && (
              <p className="mt-2 text-sm text-status-error">
                {errors.birthDate.message === 'patientAgeError'
                  ? forSelf
                    ? 'Patients who are under 18 must have their parent/guardian create an account.'
                    : 'Patients who are 18 and older must create their own account.'
                  : errors.birthDate.message}
              </p>
            )}
          </div>

          {/* Student ID or Email */}
          {!is4thFieldEmail ? (
            <div className="xs:col-span-2">
              <label
                htmlFor="externalId"
                className="flex items-center gap-1 text-base font-semibold text-text-primary xs:text-sm"
              >
                {clientInfo?.clientType === 'EMPLOYER'
                  ? 'Employee ID'
                  : 'Student ID'}{' '}
                {clientInfo?.clientType === 'EDUCATION' && (
                  <Tooltip
                    content="You can find this information on the school ID, or from the school administrators."
                    position="right"
                  >
                    <InformationCircleIcon className="h-4 w-4" />
                  </Tooltip>
                )}
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  className={inputValidClass}
                  placeholder="Enter"
                  {...register('externalId')}
                />
                {errors.externalId && (
                  <p className="mt-1 text-sm text-status-error">
                    {errors.externalId.message}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="xs:col-span-2">
              <label
                htmlFor="externalId"
                className="flex items-center gap-1 text-base font-semibold text-text-primary xs:text-sm"
              >
                {clientInfo?.clientType === 'EMPLOYER'
                  ? 'Work email'
                  : 'Student email'}
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  className={inputValidClass}
                  placeholder="Enter"
                  {...register('externalId')}
                />
                {errors.externalId && (
                  <p className="mt-1 text-sm text-status-error">
                    {errors.externalId.message}
                  </p>
                )}
              </div>
            </div>
          )}

          {/* Buttons */}
          <div className="col-span-2 my-8 flex w-full items-center">
            <button
              type="button"
              className={`mr-2 w-1/2 ${tertiaryButtonClass}`}
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              Try again
            </button>
            <button
              type="submit"
              className={`w-1/2 ${primaryButtonClass}`}
              disabled={!isValid || isLoading}
            >
              {isLoading ? (
                <>
                  <RefreshIcon className="loader h-5 w-5" aria-hidden="true" />
                  Loading
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InvalidVerification
